import React, { HTMLProps } from 'react';
import clsx from 'clsx'
import { isMs as IsMs } from "../../utils";

interface H2Props extends HTMLProps<HTMLHeadingElement> {
  underline?: boolean;
  color?: string
}

export const H2 = ({
  children,
  underline = false,
  ...props
}: H2Props) => {
  const isMs: boolean = IsMs();

  return (
    <p
      className={clsx("text-darkBlue text-3xl font-bold after:block leading-10", {
        'after:bg-purple-600': isMs,
        'after:bg-indigo-600': !isMs,
        'after:w-8 after:h-1 after:rounded': underline
      })}
      {...props}
    >
      {children}
    </p>
  );
};

export default H2;
