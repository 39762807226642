import React, { HTMLProps, useContext } from "react";
import clsx from "clsx";

import { MainContext } from "~/context";

import styles from "./styles.module.css";


const ContentWrapper = ({ children }: HTMLProps<HTMLDivElement>) => {
  const { user } = useContext(MainContext);
  const windowGlobal = typeof window !== "undefined" && window;

  const isTeacherZone: boolean =
    user && windowGlobal?.location?.pathname.includes("/notes");

  return (
    <div
      className={
        clsx(
          `text-gray-600 mt-3 ${styles.ContentWrapper}`,
          {
            "max-w-xl": !isTeacherZone
          }
        )
      }
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
