import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import clsx from "clsx";

import { isMs as IsMs } from "../../utils";

type AccordionHeaderProps = {
  headerText: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

type AccordionBodyProps = {
  children: ReactNode;
  open: boolean;
}

type AccordionProps = {
  children: ReactNode;
  className?: string;
  headerText: string;
  open: boolean;
  [rest:string]: unknown;
}

const AccordionHeader = ({ headerText, open, setOpen }: AccordionHeaderProps) => {
  return (
    <div onClick={() => setOpen(!open)} className="flex cursor-pointer p-3">
      <span className="text-lg font-bold">{headerText}</span>
      <span className="ml-auto">
        <i
          className={clsx(
            "text-lg fas fa-chevron-down transform transition-all",
            {
              "rotate-180": open,
              "fa-chevron-down": !open,
            }
          )}
        />
      </span>
    </div>
  )
}

const AccordionBody = ({ children, open }: AccordionBodyProps) => {
  return (
      <div
        className={clsx(
          "flex bg-white p-4 rounded-b",
          {
            "block": open,
            "hidden": !open
          }
        )}
      >
        {children}
      </div>
  );
}

const Accordion = ({ children, className, headerText, open: initialOpen, ...rest }: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(initialOpen);
  const isMs: boolean = IsMs();

  return (
    <div
      className={clsx(
        className ? `w-full rounded shadow-lg ${className}`: "w-full rounded shadow",
        {
          "bg-landing-page-yellow bg-opacity-30": !isMs && headerText=="What is health literacy?",
          "bg-landing-page-green bg-opacity-30": !isMs && headerText=="What is media literacy?",
          "bg-indigo-500 bg-opacity-30": !isMs && headerText=="What is science literacy?",
          "bg-indigo-100": !isMs && (headerText!=="What is health literacy?") && (headerText!=="What is media literacy?")&& (headerText!=="What is science literacy?"),
          "bg-purple-100": isMs
        }
      )}
      {...rest}
    >
      <AccordionHeader open={open} setOpen={setOpen} headerText={headerText} />
      <AccordionBody open={open}>{children}</AccordionBody>
    </div>
  )
}

export default Accordion;
